import { useState, useEffect } from "react";
import { supabase } from "../db";

export const useMonthHighlightedRaceEvents = () => {
  const [highlightedRaceEvents, setHighlightedRaceEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHighlightedRaceEvents = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from(`${process.env.REACT_APP_TABLE_NAME}`)
          .select()
          .eq("banner_highlight", "Y");
        if (error) {
          throw error;
        }
        setHighlightedRaceEvents(data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching highlighted race events:", error.message);
        setLoading(false);
      }
    };
    fetchHighlightedRaceEvents();
    return () => {};
  }, []);
  return { highlightedRaceEvents, loading };
};
