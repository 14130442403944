import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/scss/global.scss";
import Banner from "./components/banner";
import Footer from "./components/footer";
import Header from "./components/header";
import Getintouch from "./components/getintouch";
import Calender from "./components/calender";
import { useState } from "react";

function App() {
  const [isFilterClear, setFilterClear] = useState(false);
  return (
    <>
      <Header setFilterClear={setFilterClear} />
      <Banner />
      <Calender isFilterClear={isFilterClear} setFilterClear={setFilterClear} />
      <Getintouch />
      <Footer />
    </>
  );
}

export default App;
