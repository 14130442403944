import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Modal,
  Ratio,
  Row,
  Spinner,
} from "react-bootstrap";
import Slider from "react-slick";
import arrow from "./../assets/images/arrow.svg";
import calendarIcon from "./../assets/images/calendar.svg";
import locationIcon from "./../assets/images/location-icon.svg";
import trailIcon from "./../assets/images/up-icon.svg";
import gymIcon from "./../assets/images/gym.svg";
import roadIcon from "./../assets/images/road.svg";
import noDataIcon from "./../assets/images/no-data.svg";
import downloadIcon from "./../assets/images/download.svg";

import moment from "moment";
import {
  useCountryList,
  useEventCountByMonth,
  useFilteredData,
} from "../hooks";
function Calender({ isFilterClear, setFilterClear }) {
  const { countryList } = useCountryList();
  const { eventCountByMonth } = useEventCountByMonth();
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedCountryFilter, setSelectedCountryFilter] = useState("");
  const [selectedStatusFilter, setSelectedStatusFilter] = useState("");
  const [selectedTerrain, setSelectedTerrain] = useState("");
  const [isShowWithImage, setIsShowWithImage] = useState(true);
  const [selectedPointSystem, setSelectedPointSystem] = useState("");
  const [selectedDistanceCategory, setSelectedDistanceCategory] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const handleClearFilter = () => {
    setSelectedMonth("");
    setSelectedCountryFilter("");
    setSelectedStatusFilter("");
    setSelectedTerrain("");
    setSelectedPointSystem("");
    setIsShowWithImage(true);
    setSelectedDistanceCategory("");
    setSelectedYear("");
    setMonthFilter("");
  };

  useEffect(() => {
    if (isFilterClear) {
      handleClearFilter();
    }
    setFilterClear(false);
  }, [isFilterClear, setFilterClear]);

  const { filteredData, loading } = useFilteredData({
    status: selectedStatusFilter,
    month: selectedMonth,
    country: selectedCountryFilter,
    type: selectedTerrain,
    pointSystem: selectedPointSystem,
    distanceCategory: selectedDistanceCategory,
    year: selectedYear,
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalData, setModalData] = useState({});

  const handleModal = (race) => {
    handleShow();
    setModalData(race);
  };
  const [monthFilter, setMonthFilter] = useState("");
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    const [month, year] = selectedValue.split(" ");
    setSelectedYear(year);
    setSelectedMonth(
      moment(month, "MMM").format("MMMM").length > 5
        ? month
        : moment(month, "MMM").format("MMMM")
    );
    setMonthFilter(selectedValue);
    setIsShowWithImage(false);
  };

  return (
    <section className="calender-section">
      <Container>
        <Row className="justify-content-center">
          <Col md={12}>
            <h2>
              Your gateway to a competitive life: discover races, schedule your
              year, and let’s celebrate your victories together.
            </h2>
          </Col>
        </Row>
        <hr />
        <div>
          <div className="d-flex flex-column flex-md-row align-md-center justify-content-lg-between mb-3 mb-md-4 mb-lg-5">
            <h4 className="mb-3 mb-md-0">Calendar</h4>
            <div className="filter-list d-flex overflow-auto">
              <select value={monthFilter} onChange={handleSelectChange}>
                <option value="">Month</option>
                {eventCountByMonth &&
                  eventCountByMonth.map((monthData, index) => {
                    if (monthData.eventCount > 0) {
                      const formattedMonth = moment(
                        monthData.month,
                        "MMM"
                      ).format("MMMM");
                      const displayMonth =
                        formattedMonth.length > 5
                          ? `${monthData.month} ${moment(
                              monthData.year,
                              "YYYY"
                            ).format("YY")}`
                          : `${formattedMonth} ${moment(
                              monthData.year,
                              "YYYY"
                            ).format("YY")}`;

                      return (
                        <option
                          key={index}
                          value={`${monthData.month} ${monthData.year}`}
                        >
                          {displayMonth}
                        </option>
                      );
                    }
                    return null;
                  })}
              </select>

              <select
                value={selectedCountryFilter}
                onChange={(e) => {
                  setSelectedCountryFilter(e.target.value);
                  setIsShowWithImage(false);
                }}
              >
                <option value="" selected>
                  Country
                </option>
                {countryList &&
                  countryList.map((country, index) => {
                    return (
                      <option value={country} key={index}>
                        {country}
                      </option>
                    );
                  })}
              </select>
              <select
                value={selectedTerrain}
                onChange={(e) => {
                  setSelectedTerrain(e.target.value);
                  setIsShowWithImage(false);
                }}
              >
                <option value="" selected>
                  Terrain
                </option>
                <option value="Trail">Trail</option>
                <option value="Gym">Gym</option>
                <option value="Road">Road</option>
              </select>
              <select
                value={selectedDistanceCategory}
                onChange={(e) => {
                  setSelectedDistanceCategory(e.target.value);
                  setIsShowWithImage(false);
                }}
              >
                <option value="" selected>
                  Distance
                </option>
                <option value="<10K">&lt;10K</option>
                <option value="10-20K">10-20K</option>
                <option value="20-40K">20-40K</option>
                <option value="40-60K">40-60K</option>
                <option value="60K+">60K+</option>
              </select>
              <select
                value={selectedPointSystem}
                onChange={(e) => {
                  setSelectedPointSystem(e.target.value);
                  setIsShowWithImage(false);
                }}
              >
                <option value="" selected>
                  Point System
                </option>
                <option value="UTMB">UTMB</option>
                <option value="ITRA">ITRA</option>
                <option value="ITRA / UTMB">ITRA / UTMB</option>
                <option value="NA">OTHER</option>
              </select>
              <select
                value={selectedStatusFilter}
                onChange={(e) => {
                  setSelectedStatusFilter(e.target.value);
                  setIsShowWithImage(false);
                }}
              >
                <option value="" selected>
                  Status
                </option>
                <option value="Closed">CLOSED</option>
                <option value="Open">OPEN</option>
              </select>

              <Button
                onClick={handleClearFilter}
                style={{
                  background: "#10948f",
                  border: "1px solid white",
                  borderRadius: "0px",
                  fontWeight: 500,
                }}
              >
                Clear All filter
              </Button>
            </div>
          </div>
          <div className="calender-list d-flex flex-column flex-md-row">
            <div className="month-list">
              {eventCountByMonth &&
                eventCountByMonth.map((eventMonth, index) => {
                  if (eventMonth.eventCount > 0) {
                    const formattedMonth = moment(
                      eventMonth.month,
                      "MMM"
                    ).format("MMMM");
                    const displayMonth =
                      formattedMonth.length > 5
                        ? eventMonth.month
                        : formattedMonth;
                    const isSelected =
                      selectedMonth === displayMonth &&
                      selectedYear === eventMonth.year;

                    return (
                      <div
                        key={index}
                        className={`item ${isSelected ? "active" : ""}`}
                        onClick={() => {
                          setIsShowWithImage(false);
                          setSelectedYear(eventMonth.year);
                          setSelectedMonth(displayMonth);
                          setMonthFilter(
                            `${eventMonth.month} ${eventMonth.year}`
                          );
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          {displayMonth}
                          <div>
                            {moment(eventMonth.year, "YYYY").format("YY")}
                          </div>
                        </div>
                        <ul className="booking d-flex">
                          {Array.from({ length: eventMonth.eventCount }).map(
                            (_, index) => (
                              <li key={index}></li>
                            )
                          )}
                        </ul>
                      </div>
                    );
                  }
                })}
            </div>
            <div className="calender-items flex-grow-1">
              {loading ? (
                <div className="loading-wrapper">
                  <Spinner className="loading" animation="grow" />
                </div>
              ) : filteredData && filteredData.length > 0 ? (
                filteredData.map((race, index) => {
                  return (
                    <div
                      key={index}
                      className="item d-flex flex-column flex-lg-row"
                    >
                      <div>
                        <div
                          className={
                            race.registration_status === "Closed"
                              ? "status closed"
                              : "status"
                          }
                        >
                          {race.registration_status?.toUpperCase()}
                        </div>
                        <h4
                          className="d-flex align-items-center"
                          onClick={() => {
                            const registrationLink =
                              race.main_website.startsWith("http://") ||
                              race.main_website.startsWith("https://")
                                ? race.main_website
                                : `http://${race.main_website}`;
                            window.open(registrationLink, "_blank");
                          }}
                        >
                          {race.race_name}
                          {race.type === "Trail" && (
                            <img src={trailIcon} alt="trail" />
                          )}
                          {race.type === "Road" && (
                            <img src={roadIcon} alt="road" />
                          )}
                          {race.type === "Gym" && (
                            <img src={gymIcon} alt="gym" />
                          )}
                        </h4>
                        <div className="item-info d-flex">
                          <div className="info main-item">
                            {race.country?.toUpperCase()}
                            {race.point_system && `• ${race.point_system}`}
                          </div>
                          <div
                            className="info"
                            style={{
                              display: "flex",
                              gap: "8px",
                            }}
                          >
                            <img src={locationIcon} alt="location" />
                            {race.location}
                          </div>
                          {race.date && (
                            <div
                              className="info"
                              style={{
                                display: "flex",
                                gap: "8px",
                              }}
                            >
                              <img src={calendarIcon} alt="calendar" />{" "}
                              {moment(race.date).format("MMM DD")}
                            </div>
                          )}
                        </div>
                        <p>{race.race_description}</p>
                        <div className="route-list d-flex">
                          {race.racing_tracks &&
                            race.racing_tracks.map(
                              (track, index) =>
                                track.gpx === "Y" && (
                                  <div
                                    key={index}
                                    className="route d-flex justify-content-between align-items-center"
                                  >
                                    <div className="route-content d-flex justify-content-between align-items-center w-100">
                                      <span>{track.distance} K</span>
                                      {track?.race_track_image_url && (
                                        <img
                                          src={track.race_track_image_url}
                                          alt="track"
                                        />
                                      )}
                                    </div>
                                    {track.race_gpx_url && (
                                      <div
                                        className="route-overlay"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          window.open(track.race_gpx_url);
                                        }}
                                      >
                                        <div className="overlay-content">
                                          <img
                                            src={downloadIcon}
                                            alt="download"
                                          />
                                          <div>GPX</div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                            )}
                        </div>
                        {/* <div className="route-list d-flex">
                          {race.racing_tracks &&
                            race.racing_tracks.map(
                              (track, index) =>
                                track.gpx === "Y" && (
                                  <div
                                    onClick={() => {
                                      window.open(track.race_gpx_url);
                                    }}
                                    key={index}
                                    className="route d-flex justify-content-between align-items-center"
                                  >
                                    {track.distance} K
                                    {track?.race_track_image_url && (
                                      <img
                                        src={track.race_track_image_url}
                                        alt="track"
                                        style={{
                                          marginRight: "20px",
                                        }}
                                      />
                                    )}
                                  </div>
                                )
                            )}
                        </div> */}
                        {race.registration_link !== "" && (
                          <button
                            className="theme-btn"
                            onClick={() => {
                              const registrationLink =
                                race.registration_link.startsWith("http://") ||
                                race.registration_link.startsWith("https://")
                                  ? race.registration_link
                                  : `http://${race.registration_link}`;
                              window.open(registrationLink, "_blank");
                            }}
                          >
                            Register Now
                            <img src={arrow} className="arrow" alt="arrow" />
                          </button>
                        )}
                      </div>
                      {isShowWithImage && race.media_links && (
                        <div className="img-slider">
                          {race.media_links && (
                            <Slider {...settings}>
                              {race.media_links.map((embedItem, index) => {
                                return (
                                  <div key={index}>
                                    <Ratio aspectRatio="16x9">
                                      <img
                                        src={embedItem.image}
                                        alt="treaking1"
                                      />
                                    </Ratio>
                                  </div>
                                );
                              })}
                            </Slider>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="no-race-found">
                  <img src={noDataIcon} alt="No Race Found" />
                  <span className="no-race-text">No Race Found !!</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>

      <Modal show={show} centered onHide={handleClose} size="lg">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="popup-items">
            <div className="item d-flex flex-column flex-lg-row mb-0">
              <div>
                <div
                  className={
                    modalData.registration_status === "Closed"
                      ? "status closed"
                      : "status"
                  }
                >
                  {modalData?.registration_status?.toUpperCase()}
                </div>
                <h4 className="d-flex align-items-center">
                  {modalData.race_name}
                  {modalData.type === "Trail" && (
                    <img src={trailIcon} alt="trail" />
                  )}
                  {modalData.type === "Road" && (
                    <img src={roadIcon} alt="road" />
                  )}
                  {modalData.type === "Gym" && <img src={gymIcon} alt="gym" />}
                </h4>
                <div className="item-info d-flex">
                  <div className="info main-item">
                    {modalData.country?.toUpperCase()} •{modalData.point_system}{" "}
                  </div>
                </div>
                <p>{modalData.race_description}</p>
                <div className="detail-list d-flex flex-column flex-md-row align-items-md-center">
                  <div className="detail d-flex align-items-center">
                    <div className="detail-img d-flex justify-content-center">
                      <img src={locationIcon} alt="location" />
                    </div>
                    <p className="mb-0"> {modalData.location}</p>
                  </div>
                  {modalData.data && (
                    <div className="detail d-flex align-items-center mb-0">
                      <div className="detail-img d-flex justify-content-center">
                        <img src={calendarIcon} alt="calender" />
                      </div>
                      <p className="mb-0">
                        {moment(modalData.date).format("MMM DD")}
                      </p>
                    </div>
                  )}
                </div>

                <div className="route-list d-flex">
                  {modalData.racing_tracks &&
                    modalData.racing_tracks.map(
                      (track, index) =>
                        track.gpx === "Y" && (
                          <div className="route d-flex justify-content-between align-items-center">
                            {track.distance}
                            {track.race_track_image_url && (
                              <img
                                src={track.race_track_image_url}
                                alt="track"
                              />
                            )}
                          </div>
                        )
                    )}
                </div>
                {modalData.registration_link !== "" && (
                  <button
                    className="theme-btn"
                    onClick={() => {
                      const registrationLink =
                        modalData.registration_link.startsWith("http://") ||
                        modalData.registration_link.startsWith("https://")
                          ? modalData.registration_link
                          : `http://${modalData.registration_link}`;
                      window.open(registrationLink, "_blank");
                    }}
                  >
                    Register Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Calender;
