import React, { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import moment from "moment";
import { useRaceSearch } from "../hooks";
import downloadIcon from "./../assets/images/download.svg";
import arrow from "./../assets/images/arrow.svg";
import logo from "./../assets/images/logo.svg";
import searchIcon from "./../assets/images/search.svg";
import rightUpIcon from "./../assets/images/arrow-right-up.svg";
import calendarIcon from "./../assets/images/calendar.svg";
import locationIcon from "./../assets/images/location-icon.svg";
import trailIcon from "./../assets/images/up-icon.svg";
import gymIcon from "./../assets/images/gym.svg";
import roadIcon from "./../assets/images/road.svg";

function Header({ setFilterClear }) {
  const [searchText, setSearchText] = useState("");
  const { filteredEvents } = useRaceSearch(
    searchText.length > 2 ? searchText : ""
  );

  const scrollToTop = () => {
    setFilterClear(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [showSearchResults, setShowSearchResults] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleModal = (e, race) => {
    e.preventDefault();
    e.stopPropagation();
    setModalData(race);
    handleShow();
    setSearchText("");
  };

  return (
    <>
      <Modal show={show} centered size="lg" onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="popup-items">
            <div className="item d-flex flex-column flex-lg-row mb-0">
              <div>
                <div
                  className={
                    modalData.registration_status === "Closed"
                      ? "status closed"
                      : "status"
                  }
                >
                  {modalData.registration_status?.toUpperCase()}
                </div>
                <h4 className="d-flex align-items-center">
                  {modalData.race_name}
                  {modalData.type === "Trail" && (
                    <img src={trailIcon} alt="trail" />
                  )}
                  {modalData.type === "Road" && (
                    <img src={roadIcon} alt="road" />
                  )}
                  {modalData.type === "Gym" && <img src={gymIcon} alt="gym" />}
                </h4>
                <div className="item-info d-flex">
                  <div className="info main-item">
                    {modalData.country?.toUpperCase()}
                    {modalData.point_system && `• ${modalData.point_system}`}
                  </div>
                </div>
                <p>{modalData.race_description}</p>
                <div className="detail-list d-flex flex-column flex-md-row align-items-md-center">
                  <div className="detail d-flex align-items-center">
                    <div className="detail-img d-flex justify-content-center">
                      <img src={locationIcon} alt="location" />
                    </div>
                    <p className="mb-0">{modalData.location}</p>
                  </div>
                  <div className="detail d-flex align-items-center mb-0">
                    <div className="detail-img d-flex justify-content-center">
                      <img src={calendarIcon} alt="calendar" />
                    </div>
                    <p className="mb-0">
                      {moment(modalData.date).format("MMM DD")}
                    </p>
                  </div>
                </div>

                <div className="route-list d-flex">
                  {modalData?.racing_tracks &&
                    modalData?.racing_tracks.map(
                      (track, index) =>
                        track.gpx === "Y" && (
                          <div
                            key={index}
                            className="route d-flex justify-content-between align-items-center"
                          >
                            <div
                              key={index}
                              className="route d-flex justify-content-between align-items-center"
                            >
                              {track.distance} K
                              {track?.race_track_image_url && (
                                <img
                                  src={track.race_track_image_url}
                                  alt="track"
                                />
                              )}
                            </div>
                            {track.race_gpx_url && (
                              <div
                                className="route-overlay"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(track.race_gpx_url);
                                }}
                              >
                                <div className="overlay-content">
                                  <img src={downloadIcon} alt="download" />
                                  <div>GPX</div>
                                </div>
                              </div>
                            )}
                          </div>
                        )
                    )}
                </div>
                {modalData.registration_link !== "" && (
                  <button
                    className="theme-btn"
                    onClick={() => {
                      const registrationLink =
                        modalData.registration_link.startsWith("http://") ||
                        modalData.registration_link.startsWith("https://")
                          ? modalData.registration_link
                          : `http://${modalData.registration_link}`;
                      window.open(registrationLink, "_blank");
                    }}
                  >
                    Register Now
                    <img src={arrow} className="arrow" alt="arrow" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <header className="header">
        <Container>
          <div className="d-flex align-items-center">
            <img
              src={logo}
              className="App-logo"
              alt="logo"
              width={100}
              style={{
                cursor: "pointer",
              }}
              onClick={scrollToTop}
            />
            <div className="form-group position-relative">
              <input
                type="text"
                name="find"
                value={searchText}
                placeholder="find your race, schedule your year..."
                onChange={(e) => {
                  setSearchText(e.target.value);
                  setShowSearchResults(true);
                }}
                onFocus={() => setShowSearchResults(true)}
              />
              <button className="search position-absolute">
                <img src={searchIcon} alt="search" />
              </button>
              {showSearchResults && searchText && (
                <div className="search-result">
                  <p>Suggested</p>
                  {filteredEvents &&
                    filteredEvents.length > 0 &&
                    filteredEvents.map((event) => (
                      <div
                        style={{ zIndex: 99, cursor: "pointer" }}
                        onClick={(e) => handleModal(e, event)}
                        key={event.id}
                        className="search-item d-flex justify-content-between align-items-center"
                        id="find"
                      >
                        <span>
                          <span className="filled">{event.race_name}</span>
                        </span>
                        <img src={rightUpIcon} alt="search" />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </Container>
      </header>
    </>
  );
}

export default Header;
