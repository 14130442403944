import { useState, useEffect } from "react";
import { supabase } from "../db";

export const useCountryList = () => {
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const { data, error } = await supabase
          .from(`${process.env.REACT_APP_TABLE_NAME}`)
          .select("country");
        if (error) {
          throw error;
        }
        return data;
      } catch (error) {
        console.error("Error fetching country:", error.message);
        return [];
      }
    };

    const fetchAndSetCountries = async () => {
      const data = await fetchCountryList();
      const uniqueCountries = Array.from(
        new Set(data.map((event) => event.country))
      );
      setCountryList(uniqueCountries);
    };

    fetchAndSetCountries();
  }, []);

  return { countryList };
};
