import { Col, Container, Row } from "react-bootstrap";
import sms from "./../assets/images/sms.svg";

function Getintouch() {
  return (
    <section className="get-in-touch">
      <Container>
        <Row className="align-items-center d-flex justify-content-center connect">
          <Col md={8} lg={7}>
            <h3>Connect, Compete, Thrive: Get in Touch Today.</h3>
            <p>
              We are actively looking for feedback, partners, and supporters!
              Let us know what features you want to see and we’ll try to make it
              happen.{" "}
            </p>
          </Col>
          <Col md={8} lg={5}>
            <div className="d-flex email-submit">
              <form className="form-group position-relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                />
                <img
                  src={sms}
                  className="sms-img position-absolute"
                  alt="sms"
                />
              </form>
              <button className="submit-btn">Submit</button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Getintouch;
