import { useState, useEffect } from "react";
import { supabase } from "../db";
import moment from "moment";
export const monthMap = {
  Jan: "01",
  Feb: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};
export const useFilteredData = (filteres) => {
  const { status, month, country, type, pointSystem, year, distanceCategory } =
    filteres;
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-11

        // Start date: first day of the current month in the current year
        const startDate = moment(
          `${currentYear}-${currentMonth}-01`,
          "YYYY-MM-DD"
        )
          .startOf("month")
          .format("YYYY-MM-DD");

        // End date: last day of the current month in the next year
        const endDate = moment(
          `${currentYear + 1}-${currentMonth}-01`,
          "YYYY-MM-DD"
        )
          .endOf("month")
          .format("YYYY-MM-DD");

        const filtersApplied =
          status ||
          month ||
          country ||
          type ||
          pointSystem ||
          year ||
          distanceCategory;
        // Build the initial query
        let query = supabase
          .from(`${process.env.REACT_APP_TABLE_NAME}`)
          .select("*")
          .gte("date", startDate)
          .lt("date", endDate)
          .order("date", { ascending: true });

        if (!filtersApplied) {
          query = query.eq("month_highlight", "Y");
        }

        if (month && year) {
          const startDate = moment(`${year}-${monthMap[month]}`)
            .startOf("month")
            .format("YYYY-MM-DD");

          // End date: last day of the specified month in the specified year
          const endDate = moment(`${year}-${monthMap[month]}`)
            .endOf("month")
            .format("YYYY-MM-DD");

          // Build the query
          query = supabase
            .from(`${process.env.REACT_APP_TABLE_NAME}`)
            .select("*")
            .gte("date", startDate)
            .lt("date", endDate)
            .order("date", { ascending: true });
        }
        if (pointSystem) {
          query = query.eq("point_system", pointSystem);
        }
        if (country) {
          query = query.eq("country", country);
        }
        if (status) {
          query = query.eq("registration_status", status);
        }
        if (type) {
          query = query.eq("type", type);
        }

        const { data, error } = await query;
        if (distanceCategory) {
          const range = getRange(distanceCategory);
          const filteredRaceData = filterByDistance(data, range.min, range.max);
          setFilteredData(filteredRaceData || []);
          setLoading(false);
        } else {
          console.log(data.length, "DATA_LENGTH");
          setFilteredData(data || []);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [status, month, country, type, pointSystem, year, distanceCategory]);

  return { filteredData, loading };
};

const getRange = (value) => {
  switch (value) {
    case "<10K":
      return { min: 0, max: 10 };
    case "10-20K":
      return { min: 10, max: 20 };
    case "20-40K":
      return { min: 20, max: 40 };
    case "40-60K":
      return { min: 40, max: 60 };
    case "60K+":
      return { min: 60, max: 1000 };
    default:
      return { min: null, max: null };
  }
};

const filterByDistance = (data, min, max) => {
  return data.filter((track) => {
    return track.racing_tracks.some((race) => {
      if (race.gpx === "Y") {
        const distance =
          typeof race.distance === "string"
            ? parseInt(race.distance)
            : race.distance;
        return distance >= min && distance <= max;
      }
      return false;
    });
  });
};
