import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from './../assets/images/logo.svg';
import arrow from './../assets/images/arrow-black.svg';
import instagram from './../assets/images/instagram.svg';
import twitter from './../assets/images/Twitter.svg';

function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row className='align-items-center'>
          <Col md={3}>
            <img src={logo} className="App-logo" alt="logo" width={100} />
            <p>Whyr is a passion project between longtime friends who found sustainable health and joy through competition. Our goal is to make it easier for everyone to find and discover events that works for their individual lifestyles.</p>
          </Col>
          <Col md={9} className='d-flex justify-content-end footer-detail'>
            <div className='d-flex footer-nav'>
              <Link to="/" className='d-flex align-items-center'>the Boring stuff
                <img src={arrow} className="arrow" alt="arrow" />
              </Link>
              <Link to="/" className='d-flex align-items-center'>Privacy policy
                <img src={arrow} className="arrow" alt="arrow" />
              </Link>
              <Link to="/" className='d-flex align-items-center'>Terms of service
                <img src={arrow} className="arrow" alt="arrow" />
              </Link>
              <Link to="/" className='d-flex align-items-center'>FAQs
                <img src={arrow} className="arrow" alt="arrow" />
              </Link>
            </div>
            <div className='d-flex social-media'>
              <a href='/' className="d-flex justify-content-center">
                <img src={instagram} alt="logo" />
              </a>
              <a href='/' className="d-flex justify-content-center">
                <img src={twitter} alt="logo" />
              </a>
            </div>
          </Col>
        </Row>
        <p className='copyright mb-0'>© Whyr Limited 2024</p>
      </Container>

    </footer >
  );
}

export default Footer;
