import { useState, useEffect } from "react";
import { supabase } from "../db";

export const useRaceSearch = (raceName) => {
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRaceEvents = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from(`${process.env.REACT_APP_TABLE_NAME}`)
          .select();
        if (error) {
          throw error;
        }
        return data;
      } catch (error) {
        console.error("Error fetching race events:", error.message);
        return [];
      } finally {
        setLoading(false);
      }
    };

    const filterEvents = async () => {
      const data = await fetchRaceEvents();
      if (raceName) {
        const filtered = data.filter((event) =>
          event.race_name.toLowerCase().includes(raceName.toLowerCase())
        );
        setFilteredEvents(filtered);
      } else {
        setFilteredEvents(data);
      }
    };

    filterEvents();
  }, [raceName]);

  return { filteredEvents, loading };
};
