import { Carousel, Col, Container, Row } from "react-bootstrap";
import location from "./../assets/images/location.svg";
import calender from "./../assets/images/calendar.svg";
import arrow from "./../assets/images/arrow.svg";
import moment from "moment";
import { useMonthHighlightedRaceEvents } from "../hooks";

const Banner = () => {
  // useEffect(() => {
  //   const insertBulkData = async () => {
  //     const { data, error } = await supabase
  //       .from("racing_details")
  //       .insert(dataBaseData);

  //     if (error) {
  //       console.error("Error inserting data:", error);
  //     } else {
  //       console.log("Data inserted successfully:", data);
  //     }
  //   };

  //   insertBulkData();
  // }, []);
  const { highlightedRaceEvents } = useMonthHighlightedRaceEvents();
  return (
    <section className="banner-section">
      <Container className="position-relative">
        <div className="dark-circle top-50 translate-middle-y"></div>
        <Row className="justify-content-center align-items-center position-relative ">
          <Col md={10}>
            <Carousel interval={6000} pause={false} controls={false}>
              {highlightedRaceEvents.map((event) => (
                <Carousel.Item key={event.id} style={{ height: "441px" }}>
                  <div className="banner">
                    {event.point_system ? (
                      <h3 className="badge">{event.point_system}</h3>
                    ) : (
                      <div className="newBadge"></div>
                    )}
                    <h1>{event.race_name}</h1>
                    <div className="detail d-flex align-items-center">
                      <div className="detail-img d-flex justify-content-center">
                        <img src={location} alt="location" />
                      </div>
                      <h4 className="mb-0">{`${event.location}, ${event.country}`}</h4>
                    </div>
                    {event.date && (
                      <div className="detail d-flex align-items-center mb-0">
                        <div className="detail-img d-flex justify-content-center">
                          <img src={calender} alt="calender" />
                        </div>
                        <h4 className="mb-0">
                          {moment(event.date).format("MMMM DD, YYYY")}
                        </h4>
                      </div>
                    )}
                    {event.registration_link && (
                      <button
                        className="theme-btn"
                        onClick={() => {
                          const registrationLink =
                            event.registration_link.startsWith("http://") ||
                            event.registration_link.startsWith("https://")
                              ? event.registration_link
                              : `http://${event.registration_link}`;
                          window.open(registrationLink, "_blank");
                        }}
                      >
                        Register Now
                        <img src={arrow} className="arrow" alt="arrow" />
                      </button>
                    )}
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
