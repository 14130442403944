import { useState, useEffect } from "react";
import { supabase } from "../db";
import moment from "moment";

export const useEventCountByMonth = () => {
  const [eventCountByMonth, setEventCountByMonth] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEventCountByMonth = async () => {
      try {
        setLoading(true);

        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-11

        // Start date: first day of the current month in the current year
        const startDate = moment(
          `${currentYear}-${currentMonth}-01`,
          "YYYY-MM-DD"
        )
          .startOf("month")
          .format("YYYY-MM-DD");

        // End date: last day of the current month in the next year
        const endDate = moment(
          `${currentYear + 1}-${currentMonth}-01`,
          "YYYY-MM-DD"
        )
          .endOf("month")
          .format("YYYY-MM-DD");

        const { data, error } = await supabase
          .from(`${process.env.REACT_APP_TABLE_NAME}`)
          .select("date")
          .gte("date", startDate)
          .lte("date", endDate);

        if (error) {
          throw error;
        }

        // Initialize countByMonth object dynamically from current month to next year's current month
        const countByMonth = {};
        let monthCursor = moment(startDate); // Start with the startDate

        while (monthCursor.isSameOrBefore(endDate, "month")) {
          const monthKey = monthCursor.format("MMM");
          const yearKey = monthCursor.format("YYYY");

          countByMonth[`${monthKey}-${yearKey}`] = { count: 0, year: yearKey };

          monthCursor.add(1, "month"); // Move to the next month
        }

        // Iterate through fetched data and update countByMonth
        data.forEach((event) => {
          const date = moment(event.date, "YYYY-MM-DD");
          const month = date.format("MMM");
          const year = date.format("YYYY");
          const monthYear = `${month}-${year}`;

          if (countByMonth[monthYear]) {
            countByMonth[monthYear].count += 1;
          }
        });

        // Format data for output
        const formattedData = Object.keys(countByMonth).map((monthYear) => ({
          month: monthYear.split("-")[0], // Extract month from monthYear key
          year: countByMonth[monthYear].year,
          eventCount: countByMonth[monthYear].count,
        }));

        setEventCountByMonth(formattedData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching event count by month:", error.message);
        setLoading(false);
      }
    };

    fetchEventCountByMonth();
  }, []);

  return { eventCountByMonth, loading };
};
